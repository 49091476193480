import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Config = () => import('@/entities/config/config.vue');
const ConfigUpdate = () => import('@/entities/config/config-update.vue');
const ConfigDetails = () => import('@/entities/config/config-details.vue');

const Counter = () => import('@/entities/counter/counter.vue');
const CounterUpdate = () => import('@/entities/counter/counter-update.vue');
const CounterDetails = () => import('@/entities/counter/counter-details.vue');

// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'config',
      name: 'Config',
      component: Config,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/new',
      name: 'ConfigCreate',
      component: ConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/:configId/edit',
      name: 'ConfigEdit',
      component: ConfigUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'config/:configId/view',
      name: 'ConfigView',
      component: ConfigDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'counter',
      name: 'Counter',
      component: Counter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'counter/new',
      name: 'CounterCreate',
      component: CounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'counter/:counterId/edit',
      name: 'CounterEdit',
      component: CounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'counter/:counterId/view',
      name: 'CounterView',
      component: CounterDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
